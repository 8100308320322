<template>
  <div>
    <Loading v-if="loadingFull" />

    <div class="flex flex-col font-Inter mb-5 px-10">
      <div class="lg:flex lg:items-center lg:justify-between mt-4 mb-3 py-2">
        <div class="mt-1 flex flex-col justify-center">
          <h2
            class="text-xl uppercase font-medium leading-7 text-gray-900 sm:text-3xl sm:truncate"
          >
            Créer un bon d'entrée N° {{ bon.numero }}
          </h2>
          <!-- <p class="text-gray-400 text-xs mt-2 w-1/2 text-justify">
            Pour enregistrer un devis le numero est générer par le système et
            veuillez renseigner les informations obligatoire (
            <strong class="text-red-500">*</strong>) et vous pouvez a tout
            moment convertir le devis en facture ou bon de commande
          </p> -->
        </div>
      </div>

      <div class="bg-white shadow p-6">
        <div class="flex flex-col mx-auto">
          <div class=""></div>
          <el-form
            :model="bon"
            :rules="rules"
            ref="bonentreeForm"
            :label-position="labelPosition"
          >
            <div class="flex justify-between items-start">
              <div class="flex flex-col w-1/2">
                <el-form-item prop="date" label="Date">
                  <el-date-picker
                    v-model="bon.date"
                    type="date"
                    placeholder="jj-mm-aaaa"
                    format="dd MMM.yyyy"
                    value-format="yyyy-MM-dd"
                  >
                  </el-date-picker>
                </el-form-item>
                <el-form-item prop="raison">
                  <span slot="label" class="mr-7">Motif</span>
                  <el-select
                    class="w-1/2"
                    v-model="bon.raison"
                    placeholder="Veuillez selectionner"
                  >
                    <el-option
                      v-for="(item, index) in types"
                      :key="index"
                      :value="item.name"
                      :label="item.libelle"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="Description" prop="describe">
                  <el-input
                    class="w-1/2"
                    type="textarea"
                    :autosize="{ minRows: 4, maxRows: 4 }"
                    placeholder="Entrez quelque chose"
                    v-model="bon.describe"
                  ></el-input>
                </el-form-item>
              </div>
              <div class="flex flex-col justify-end items-end w-1/2">
                <div
                  class="text-4xl font-medium text-gray-900 sm:text-4xl sm:truncatemt-2 my-2"
                >
                  Total : {{ totalHT | moneyFilter }} {{user.currency_code}}
                </div>
              </div>
            </div>
          </el-form>

          <add-article
            :articles="articles"
            :items="items"
            @delete="handleDeleteItem"
            @search-article="handleSearchArticle"
            @add-item-article="handeAddArticle"
          />
        </div>
      </div>
      <page-footer>
        <div class="flex justify-between px-10 py-2">
          <div>
            <div
              class="text-2xl font-medium leading-7 text-gray-900 sm:text-2xl sm:truncatemt-2 my-2"
            >
              TOTAL: {{ totalHT | moneyFilter }} {{user.currency_code}}
            </div>
          </div>

          <div>
            <el-button
              type="primary"
              icon="el-icon-save"
              @click="createData('bonentreeForm')"
              :disabled="!itemsNoEmpty || totalHT <= 0"
              >Enregistrer et quitter
            </el-button>
          </div>
        </div>
      </page-footer>
    </div>
  </div>
</template>

<script>
import { parseTime, formatDate } from "@/utils";
import { toThousandFilter } from "@/Filters";

import { fetchRaisonVouchers, createVoucher, nextNumber } from "@/api/stock";
import AddArticle from "@/components/AddArticleVoucher";

import PageFooter from "@/components/PageFooter";
import Loading from "@/components/Loanding/Full";
import {mapGetters} from "vuex";

export default {
  name: "CreateOutingVoucher",
  filters: {
    filterTime: parseTime,
    moneyFilter: toThousandFilter,
  },
  components: { AddArticle, PageFooter, Loading },
  data() {
    return {
      type: "OUTING_VOUCHER",
      labelPosition: "top",
      componentLoanding: false,
      articles: [],
      types: [],
      loading: false,
      loadingFull: false,
      articleName: "",
      loadingArticle: false,
      items: [
        {
          article_id: "",
          article_no: "",
          article_name: "",
          price_purchase: 0,
          price_sale: 0,
          description: "",
          price: "",
          quantity: 0,
          warehouse_code: "",
          warehouse_name: "",
          stock_onhand: "",
          stocks: [],
        },
        {
          article_id: "",
          article_no: "",
          article_name: "",
          price_purchase: 0,
          price_sale: 0,
          description: "",
          price: "",
          quantity: 0,
          warehouse_code: "",
          warehouse_name: "",
          stock_onhand: "",
          stocks: [],
        },
        {
          article_id: "",
          article_no: "",
          article_name: "",
          price_purchase: 0,
          price_sale: 0,
          description: "",
          price: "",
          quantity: 0,
          warehouse_code: "",
          warehouse_name: "",
          stock_onhand: "",
          stocks: [],
        },
        {
          article_id: "",
          article_no: "",
          article_name: "",
          price_purchase: 0,
          price_sale: 0,
          description: "",
          price: "",
          quantity: 0,
          warehouse_code: "",
          warehouse_name: "",
          stock_onhand: "",
          stocks: [],
        },
        {
          article_id: "",
          article_no: "",
          article_name: "",
          price_purchase: 0,
          price_sale: 0,
          description: "",
          price: "",
          quantity: 0,
          warehouse_code: "",
          warehouse_name: "",
          stock_onhand: "",
          stocks: [],
        },
      ],
      bon: {
        numero: "",
        date: formatDate(new Date()),
        type: "OUTING_VOUCHER",
        raison: "",
        status: "",
        describe: "",
        items: [],
      },

      rules: {
        date: [
          {
            required: true,
            message: "Veuillez selectionner le client",
            trigger: "blur",
          },
        ],

        raison: [
          {
            required: true,
            message: "La raison est obligatoire",
            trigger: "blur",
          },
        ],
      },
    };
  },
  computed: {
      ...mapGetters(["user"]),
    totalHT: function () {
      return this.items.reduce(
        (total, item) => total + item.quantity * item.price,
        0
      );
    },
    itemsNoEmpty: function () {
      return this.items.some((item) => item.article_id !== "");
    },
  },
  mounted() {
    this.initData();
    this.getType();
  },
  methods: {
    async getType() {
      await fetchRaisonVouchers().then((res) => {
        this.types = res.data;
      });
    },

    async initData() {
      this.loadingFull = true;
      await nextNumber(this.type).then((res) => {
        setTimeout(() => {
          this.bon.numero = res.data.number;
          this.loadingFull = false;
        }, 1.5 * 1000);
      });
    },

    createData(bonentreeForm) {
      this.loadingFull = true;
      this.$refs[bonentreeForm].validate((valid) => {
        if (valid) {
          this.bon.items = this.items.filter(
            (el) => el.article_id !== "" && el.article_id !== undefined
          );
          createVoucher(this.bon)
            .then(() => {
              setTimeout(() => {
                this.loadingFull = false;
                this.$router.push(`/stock/index?tab=BON-SORTIE`);
              }, 1.5 * 1000);
            })
            .catch((err) => {
              this.loading = false;
              if (err.data.status === 400) {
                this.$message({
                  message:
                    "Veuillez renseigner les informations obligatoire et les articles",
                  type: "warning",
                });
              } else if (err.data.status === 490) {
                this.$message({
                  message: err.data.message,
                  type: "warning",
                });
              } else {
                this.$message({
                  message: "Erreur serveur",
                  type: "danger",
                });
              }
            });
        }
      });
    },
    handleSearchArticle(data) {
      this.articles = data;
    },
    handeAddArticle() {
      this.items.push({
        article_id: "",
        article_no: "",
        article_name: "",
        price_purchase: 0,
        price_sale: 0,
        description: "",
        price: "",
        percent_tax: 0,
        percent_discount: false,
        discount: 0,
        quantity: 0,
        warehouse_code: "",
        warehouse_name: "",
        stock_onhand: "",
        stocks: [],
      });
    },
    handleDeleteItem(id) {
      const index = this.items.findIndex((el) => el.article_id === id);
      this.items.splice(index, 1);
    },
  },
};
</script>

<style></style>
